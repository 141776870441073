/**
 * @file   src\components\CategoryMenu.tsx
 * @brief  Category Menu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Link } from 'react-router-dom';
import './Header.scss';
import Strings from '../assets/strings/Strings.json';
import CatAll from '../assets/img/icons/CatgAll';
import CatProducts from '../assets/img/icons/CatgProducts';
import CatServices from '../assets/img/icons/CatgServices';
import CatFood from '../assets/img/icons/CatgFood';
import CatThings from '../assets/img/icons/CatThings';
import CatCoupons from '../assets/img/icons/CatgCoupons';
import CatClassifieds from '../assets/img/icons/CatClassifieds';
import { setCategory, getCategory } from '../utils/localStorage';
import { categoryIds } from '../utils/enums';

const CategoryMenu = () => {
  const categoryId = getCategory();
  // handle set catrogry to local and reload the page except product details page.
  const handleCategory = (value: string) => {
    setCategory(JSON.stringify(value));
    // if (pathName !== '/productdetails') {
    //   window.location.reload();
    // }
  };
  return (
    <>
      <Link to="/vendordetails" className={categoryId === categoryIds.All ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.All}`)}>
        <CatAll />
        {Strings.Header.CategoryMenu.MenuItem1}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.PRODUCTS ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.PRODUCTS}`)}>
        <CatProducts />
        {Strings.Header.CategoryMenu.MenuItem2}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.FOOD ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.FOOD}`)}>
        <CatFood />
        {Strings.Header.CategoryMenu.MenuItem4}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.THINGSTODO ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.THINGSTODO}`)}>
        <CatThings />
        {Strings.Header.CategoryMenu.MenuItem5}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.SERVICES ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.SERVICES}`)}>
        <CatServices />
        {Strings.Header.CategoryMenu.MenuItem3}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.COUPONS ? 'active' : ''} onClick={() => handleCategory(`${categoryIds.COUPONS}`)}>
        <CatCoupons />
        {Strings.Header.CategoryMenu.MenuItem7}
      </Link>
      <Link to="/vendordetails" className={categoryId === categoryIds.CLASSIFIEDS ? 'active' : ''} onClick={(e) => handleCategory(`${categoryIds.CLASSIFIEDS}`)}>
        <CatClassifieds />
        {Strings.Header.CategoryMenu.MenuItem6}
      </Link>
    </>
  );
};

export default CategoryMenu;
