/**
 * @file   src\containers\NotificationList.tsx
 * @brief  Container for product details page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Col, Container, Row, ToggleButton, ToggleButtonGroup, Modal, InputGroup, Form, Card } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';
import { RWebShare } from 'react-web-share';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { resetProductDetailState, resetreportProductState, resetProductMessageSendState, resetshippingAddressState } from '../store/slices/productSlice';
import { productDetails, reportOptions, reportProduct, productMessages, productMessageHistory, productMessageSend, shippingAddress } from '../store/actions/productAction';
import { validatePhoneNo, nameValidation } from '../utils/authHelper';
import '../assets/styles/vendordetails.scss';
import Strings from '../assets/strings/Strings.json';
import ActionBtn from '../components/ActionBtn';
import ShareIc from '../assets/img/icons/Share';
import FlagIc from '../assets/img/icons/Flag';
import Category from '../components/Category';
import NBInputQty from '../components/NBInputQty';
import ServiceItem from '../components/ServiceTypes';
import AppsAdRight from '../components/AppsAdRight';
import * as CONSTANT from '../utils/constants';
import NBInputGroup from '../components/NBInputGroup';
import NBSelect from '../components/NBSelect';
import { getAuthToken, getGusetUser } from '../utils/localStorage';
import { categoryIds } from '../utils/enums';
import ChatIcon from '../assets/img/icons/Chat';
import Chat from '../components/Chat';
import AddressAutocomplete from '../components/AddressAutocomplete';
import Chatbtn from '../assets/img/chat-btn.svg';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import YouTube from 'react-youtube';
import { youtubePatterns, getIdfromYoutubeLink } from '../utils/authHelper';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { error_code } from '../utils/enums';
import * as alerts from '../utils/alerts';
import EditAddress from '../assets/img/icons/Edit';
import { rootCertificates } from 'tls';
import NBPhoneInput from '../components/NBPhoneInputGroup';
import { IShippingAddressData, IShippingAddressDataError } from '../interfaces/GeneralInterface';
import Autocomplete from 'react-google-autocomplete';
import{getDeliveryOption,getQty,setQty,setDeliveryOptions} from '../utils/localStorage';
import Loader from '../components/Loader';

const ProductDetail = () => {
  const dispatch: ThunkDispatch<any, undefined, AnyAction> = useAppDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();

  const { id } = useParams();
  const productDetailResponse = useAppSelector((RootReducer) => RootReducer.product.productDetails);
  const reportProductResponse = useAppSelector((RootReducer) => RootReducer.product.reportproduct);
  const reportReasonsResponse = useAppSelector((RootReducer) => RootReducer.product.reportReasons);
  const questionListResponse = useAppSelector((RootReducer) => RootReducer.product.questionList);
  const productMessageResponse = useAppSelector((RootReducer) => RootReducer.product.productMessage);
  const productMessageSendResponse = useAppSelector((RootReducer) => RootReducer.product.productMessageSend);
  const shippingAddressResponse = useAppSelector((RootReducer) => RootReducer.product.shippingAddress);
  const [vendorInfo, setvendorInfo] = useState<any>('');
  const [productImage, setProductImages] = useState<any>([]);
  const [productInfo, setProductInfo] = useState<any>('');
  const [vendorFiles, setVendorFiles] = useState<any>([]);
  const [count, setCount] = useState<number>(1);
  const [customer, setCustomer] = useState<string>('');
  const [reportReasons, setReportReasons] = useState<any>([]);
  const [questionList, setQuestionList] = useState<any>([]);
  const [productMessagesHistory, setProductMessagesHistory] = useState<any>([]);
  const [questionAsked, setQuestionAsked] = useState<any>([]);
  const [selectedMsg, setSelectedMsg] = useState<any>('');
  const [reportText, setReportText] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [viewChat, setViewChat] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [gusetUserSignOutShow, setGusetUserSignOutShow] = useState<boolean>(false);
  const [addAddressShow, setaddAddressShow] = useState<boolean>(false);
  const [addAddressCard, setaddAddressCard] = useState<boolean>(false);
  const addAddressClose = () => setaddAddressShow(false);
  const addAddressShowModal = () => setaddAddressShow(true);
  const [load, setLoad] = useState<boolean>(true);
  const [lat, setLat] = useState<string>('');
  const [long, setLong] = useState<string>('');
  const [inStoreChecked, setInStoreChecked] = useState("");
  const [shipToAddressChecked, setShipToAddressChecked] = useState("");
  const [deliveryOption, setdeliveryOption] = useState(0);
  // const initialFormError={delivery_optionError:""}
  const [deliveryError, setDeliveryError] = useState({delivery_optionError:""});
  const location = useLocation();
  const [place, setPlace] = useState<any>(null);
  const [editable, setEditable] = React.useState<boolean>(false);
  const refAddress = useRef(null);
  const [shippingAddressInfo, setAddressInfo] = useState<any>('');

  const [addAddressError, setaddAddressError] = useState({addAddress_Error:""});
  const handleClose = () => {
    setShow(false);
    setReportText('');
  };
  const handleShow = () => {
    setShow(true);
  };
  
  const [shippingAddressData, setshippingAddressData] = useState<IShippingAddressData>({
    fullname: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    statename: '',
    countryname: '',
    phone: '',
    actionType: 1,
  });

  const [shippingAddressError, setshippingAddressError] = useState<IShippingAddressDataError>({
    fullname_error: '',
    address1_error: '',
    city_error: '',
    zip_error: '',
    statename_error: '',
    countryname_error: '',
    Phone_error: '',
  });

  const shippingAddressValidation = () => {
    setshippingAddressError((setshippingAddressError) => ({
      ...setshippingAddressError,
      fullname_error: '',
      address1_error: '',
      city_error: '',
      zip_error: '',
      statename_error: '',
      countryname_error: '',
      Phone_error: '',
    }));
    let isValid = true;
    const fullNameErrMsg = nameValidation(shippingAddressData?.fullname);
    const phoneNumberErrMsg = validatePhoneNo(shippingAddressData?.phone);
    if ((shippingAddressData?.fullname?.trim() ?? '') === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        fullname_error: alerts.ENTER_FULLNAME,
      }));
    }else if (fullNameErrMsg !== '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        fullname_error: fullNameErrMsg,
      }));
    } 

    if ((shippingAddressData?.address1?.trim() ?? '') === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        address1_error: alerts.ENTER_ADDRESS,
      }));
    } 

    if ((shippingAddressData?.city?.trim() ?? '') === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        city_error: alerts.ENTER_CITY,
      }));
    } 

    if ((shippingAddressData?.statename?.trim() ?? '') === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        statename_error: alerts.ENTER_STATE,
      }));
    } 
    
    if ((shippingAddressData?.countryname?.trim() ?? '') === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        countryname_error: alerts.ENTER_COUNTRY,
      }));
    } 

    if ((shippingAddressData?.zip?.trim() ?? '')  === '') {
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        zip_error: alerts.ENTER_ZIP,
      }));
    } 
    if((shippingAddressData?.phone?.trim() ?? '') === ''){
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        Phone_error: alerts.ENTER_PHONENUMBER,
      }));
    }else if(phoneNumberErrMsg !== ''){
      isValid = false;
      setshippingAddressError((setshippingAddressError) => ({
        ...setshippingAddressError,
        Phone_error: phoneNumberErrMsg,
      }));
    }
    return isValid;
  };

  // handle mobile input data formatting.
  const handleMobileInputChange = (event: string) => {
    let inputValue = event;
    const validFormat = /^[-\d\(\)\[\]\s]+$/;
    const validDigit = /\d+/g;
    const nonDigit = /\D/;
    if (validFormat.test(inputValue)) {
      const numbers: any = inputValue.match(validDigit);
      const fullNumber = numbers.join('');
      setshippingAddressData((shippingAddressData) => ({ ...shippingAddressData, ['phone']: fullNumber }));
    } else if (!nonDigit.test(inputValue)) {
      setshippingAddressData((shippingAddressData) => ({ ...shippingAddressData, ['phone']: event }));
    }
  };

  // handle google autocomplete response data
  useEffect(() => {
    if (place) {
      let address: any = place;
      let streetNumber = '';
      let routeInfo = '';
      let plusCode = '';
      let neighborhood = '';
      let premise = '';
      let businessCity = '';
      address.forEach((item: any) => {
        if (item.types.indexOf('street_number') >= 0) {
          streetNumber = item.long_name + ' ';
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            address1: streetNumber,
          }));
        }

        if (item.types.indexOf('route') >= 0) {
          routeInfo = streetNumber + item.long_name + ' ';
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            address1: routeInfo,
          }));
        }
        if (item.types.indexOf('plus_code') >= 0) {
          if (streetNumber === '') {
            plusCode = streetNumber + routeInfo + item.long_name + ' ';
          } else {
            plusCode = routeInfo + item.long_name + ' ';
          }
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            address1: plusCode,
          }));
        }

        if (item.types.indexOf('neighborhood') >= 0) {
          if (streetNumber === '') {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + ' ';
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + ' ';
          }
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            address1: neighborhood,
          }));
        }
        if (item.types.indexOf('premise') >= 0) {
          if (streetNumber === '') {
            premise = streetNumber + routeInfo + plusCode + neighborhood + item.long_name + ' ';
          } else {
            premise = routeInfo + plusCode + neighborhood + item.long_name + ' ';
          }
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            address1: premise,
          }));
        }
        if (item.types.indexOf('administrative_area_level_1') >= 0) {
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            ['statename']: item.short_name,
          }));
        }
        if (item.types.indexOf('locality') >= 0) {
          businessCity = item.short_name;
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            ['city']: businessCity,
          }));
        } else if (item.types.indexOf('administrative_area_level_3') >= 0 && businessCity === '') {
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            ['city']: item.short_name,
          }));
        }

        if (item.types.indexOf('postal_code') >= 0) {
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            ['zip']: item.long_name,
          }));
        }
        if (item.types.indexOf('country') >= 0) {
          setshippingAddressData((shippingAddressData) => ({
            ...shippingAddressData,
            ['countryname']: item.short_name,
          }));
        }
      });
    }
  }, [place]);

  // Handle input change shipping address
  const handleShippingAddressChange = (event: { target: { name: any; value: any } }) => {
    setshippingAddressData((shippingAddressData) => ({ ...shippingAddressData, [event.target.name]: event.target.value }));
  };

  //modal open shipping address
  const handleEditAddressChanges = () => {
    setEditable(true);
    addAddressShowModal();
  };


  const deliveryOptionValidation = () => {
    let isValid=true;
    setDeliveryError({delivery_optionError:""});
    setaddAddressError({addAddress_Error:""});
    const localDeliveryOption = getDeliveryOption();
    if(localDeliveryOption){
      const parsedDeliveryOption = parseInt(localDeliveryOption, 10);
     setdeliveryOption(parsedDeliveryOption);
     if(parsedDeliveryOption===CONSTANT.STATUS_2){
      if(!shippingAddressInfo){
        isValid=false;
        setaddAddressError((prevState) => ({
          ...prevState,
          ["addAddress_Error"]: alerts.ENTER_ADDADDRESS,
        }));
        setaddAddressCard(true);
      }else{
        setaddAddressCard(false);
      }
     }
    }

   
    if (productInfo?.deliveryOption === CONSTANT.STATUS_2 && productInfo?.category_id <= categoryIds.FOOD
       && deliveryOption === CONSTANT.STATUS_0 ) {
      isValid = false;
      setDeliveryError((prevState) => ({
        ...prevState,
        ["delivery_optionError"]: alerts.ENTER_DELIVERY_OPTION,
      }));
      if(!shippingAddressInfo){
        isValid=false;
        setaddAddressError((prevState) => ({
          ...prevState,
          ["addAddress_Error"]: alerts.ENTER_ADDADDRESS,
        }));
      }
    }

    console.log(localDeliveryOption,deliveryOption,productInfo?.deliveryOption,productInfo?.category_id)
   
    return isValid;
  }

  // handle function to call products api and report options api call
  useEffect(() => {
    const payload = {
      prod_id: id,
      access_token: (getAuthToken() !== '' && guestUser === true) || null ? '' : getAuthToken(),
    };
    dispatch(productDetails(payload));
    if (!guestUser) {
      dispatch(ntfnCheck());
      dispatch(reportOptions());
      dispatch(productMessages());
      dispatch(
        productMessageHistory({
          prod_id: id,
        }),
      );
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [shippingAddressResponse]);

  // handles product details api response
  useEffect(() => {
    if (productDetailResponse.isLoading === false && productDetailResponse.isSuccess === true) {
      setLoad(false);
      if (productDetailResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (productDetailResponse.userInfo?.vendor_info && productDetailResponse.userInfo?.vendor_info[0]) {
          setvendorInfo(productDetailResponse.userInfo?.vendor_info[0]);
        }
        if (productDetailResponse.userInfo?.product_info && productDetailResponse.userInfo?.product_info[0]) {
          setProductInfo(productDetailResponse.userInfo?.product_info[0]);
        }
        if (productDetailResponse.userInfo?.prod_images) {
          let images = productDetailResponse.userInfo?.prod_images;
          if (productDetailResponse.userInfo?.product_info[0].video_link) {
            let newImgObj = {
              image_path: productDetailResponse.userInfo?.product_info[0].video_link,
              prod_id: productDetailResponse.userInfo?.product_info[0].prod_id,
            };
            images = [...images, newImgObj];
          }
          setProductImages(images);
          // setProductImages(productDetailResponse.userInfo?.prod_images);
        }
        if (productDetailResponse.userInfo?.vendor_files) {
          setVendorFiles(productDetailResponse.userInfo?.vendor_files);
        }
        if (productDetailResponse.userInfo?.customerInfo) {
          setCustomer(productDetailResponse.userInfo.customerInfo?.customerCreated);
        }
        if (productDetailResponse.userInfo?.userShippingAddress) {
          setshippingAddressData(productDetailResponse.userInfo.userShippingAddress[0]);
          setAddressInfo(productDetailResponse.userInfo.userShippingAddress[0]);
          const storeddeliveryoption = getDeliveryOption();
          if(storeddeliveryoption){
           if(parseInt(storeddeliveryoption, 10)===CONSTANT.STATUS_2)
           {
            setaddAddressCard(true);
           }else{
            setaddAddressCard(false);
           }
          }
          
        }
      } else {
        toast.error(productDetailResponse?.errorMessage);
      }
      dispatch(resetProductDetailState());
    }
  }, [productDetailResponse]);

  // handles report product reasons api response
  useEffect(() => {
    if (reportReasonsResponse.isLoading === false && reportReasonsResponse.isSuccess === true) {
      if (reportReasonsResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setReportReasons(reportReasonsResponse.resultInfo?.reasons_info);
        setSelectedValue(1);
      } else {
        toast.error(reportReasonsResponse?.errorMessage);
      }
    }
  }, [reportReasonsResponse]);

  // handles report product response
  useEffect(() => {
    if (reportProductResponse.isLoading === false && reportProductResponse.isSuccess === true) {
      if (reportProductResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(reportProductResponse?.errorMessage);
        handleClose();
        setSelectedValue(1);
        setReportText('');
      } else {
        toast.error(reportProductResponse?.errorMessage);
      }
      dispatch(resetreportProductState());
    }
  }, [reportProductResponse]);

  useEffect(() => {
    if (questionListResponse.isLoading === false && questionListResponse.isSuccess === true) {
      if (questionListResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setQuestionList(questionListResponse.resultInfo?.questions_info);
      }
    }
  }, [questionListResponse]);

  useEffect(() => {
    if (productMessageSendResponse.isLoading === false && productMessageSendResponse.isSuccess === true) {
      if (productMessageSendResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        dispatch(productMessages());
        dispatch(
          productMessageHistory({
            prod_id: id,
          }),
        );
        setQuestionAsked([...questionAsked, selectedMsg]);
        setSelectedMsg('');
      }
      dispatch(resetProductMessageSendState());
    }
  }, [productMessageSendResponse]);

  useEffect(() => {
    if (productMessageResponse.isLoading === false && productMessageResponse.isSuccess === true) {
      if (productMessageResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setProductMessagesHistory(productMessageResponse.resultInfo?.messages_info);
      }
    }
  }, [productMessageResponse]);

  // handle shipping address api call response data.
  useEffect(() => {
    if (shippingAddressResponse.isLoading === false && shippingAddressResponse.isSuccess === true) {
      if (shippingAddressResponse.errorCode === CONSTANT.API_SUCCESSCODE && shippingAddressResponse.resultInfo) {
        setaddAddressShow(false);
        setLoad(true);
      } else {
        toast.error(shippingAddressResponse.errorMessage);
      }
      dispatch<any>(resetshippingAddressState());
    }
  }, [shippingAddressResponse]);

  //validates phone number format
  const phoneFormat = (value: any) => {
    if (value) {
      let formattedPhoneNumber = value;
      if (value.length <= 3) {
        formattedPhoneNumber = value;
      } else if (value.length > 3 && value.length <= 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
      } else if (value.length > 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
      return formattedPhoneNumber;
    }
  };

  // handle add addrees submit button and initiate address  api call.
  const onSubmit = () => {
    const isValidInfo = shippingAddressValidation();
    if (isValidInfo) {
      const phoneNo = phoneFormat(shippingAddressData.phone);
      const payload = {
        fullname: shippingAddressData.fullname,
        address1: shippingAddressData.address1,
        address2: shippingAddressData.address2,
        city: shippingAddressData.city,
        zip: shippingAddressData.zip,
        statename: shippingAddressData.statename,
        countryname: shippingAddressData.countryname,
        phone: shippingAddressData.phone,
        actionType: shippingAddressInfo ? 2 : 1,
      };
      dispatch<any>(shippingAddress(payload));
    }
  };

  // handles increment and decrement count
  const countIncrement = () => {
    if (productInfo && count < productInfo?.prod_qty) {
      setCount(count + 1);
    }
  };
  const countDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
   
  useEffect(() => {
    // Get the initial value from localStorage or set it to 0
    const storedCount = getQty();
    if (storedCount) {
      setCount(parseInt(storedCount, 10)); // Parse string as integer
    }
  }, []);

  useEffect(() => { 
    // Get the initial value from localStorage or set it to 0
    const localDeliveryOption = getDeliveryOption();
    if (localDeliveryOption) {
      const parsedOption = parseInt(localDeliveryOption, 10);
      console.log("parsedOption", parsedOption);
      if (parsedOption === 1) {
        setdeliveryOption(1);
        setInStoreChecked(0); 
        setShipToAddressChecked(0);  
      } 
      else if (parsedOption === 2) {
        setdeliveryOption(2);
        setInStoreChecked(1);
        setShipToAddressChecked(1);  
      } 
      else{
        setdeliveryOption(0); // Default to 1 if no valid option found
      }
      console.log("deliveryOption", deliveryOption);
    }
  }, []); 

  // handles selected value changes
  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
  };
  const handleReportTextChange = (event: { target: { name: any; value: any } }) => {
    let inputValue = event.target.value;
    setReportText(inputValue);
  };

  // handles report product api input params
  const reportProductFunction = () => {
    if (selectedValue) {
      const params = {
        prod_id: id,
        reason_id: selectedValue,
        tell_usmore: reportText,
      };
      dispatch(reportProduct(params));
    }
  };

  // handle modal to show signout if the user is guest
  const signoutGuestConfirmationClose = () => {
    setGusetUserSignOutShow(false);
  };

  // handle confrm guest logout and navigate to login
  const logoutGuestUser = () => {
    setGusetUserSignOutShow(false);
    navigate('/signin');
    if (window.zE) {
      window.zE('messenger:set', 'cookies', false);
      // window.zE('messenger', 'hide');
    }
    // window.location.reload();
  };
  const handleselectedMessage = (value: any) => {
    setSelectedMsg(value);
  };
  const handleView = () => {
    setViewChat(!viewChat);
    setQuestionAsked([]);
  };

  const handleSendMsg = () => {
    dispatch(
      productMessageSend({
        question_id: selectedMsg,
        prod_id: id,
      }),
    );
  };

  // This will go back to the previous page in the browser history
  const handleGoBack = () => {
    window.history.back();
  };

  // handle to copy the number to clipboard
  const handleCopyPhoneNo = async () => {
    // Check if there is an extracted number
    if (vendorInfo.business_phoneno) {
      try {
        await navigator.clipboard.writeText(vendorInfo.business_phoneno);
        toast.success('Number copied to clipboard');
      } catch (err) {
        console.error('Failed to copy number to clipboard', err);
      }
    }
  };
  // handle to copy the Coupon to clipboard
  const handleCopyCoupon = async () => {
    // Check if there is an extracted number
    if (vendorInfo.business_phoneno) {
      try {
        await navigator.clipboard.writeText(productInfo?.couponName);
        toast.success('Coupon copied.');
      } catch (err) {
        console.error('Failed to copy coupon to clipboard', err);
      }
    }
  };


const handleInStoreCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(0);
      setShipToAddressChecked(0);
      setdeliveryOption(1);
      setDeliveryOptions(1);
    }
  };

  const handleShipAddressCheckBoxChange = (e) => {
    if (e.target.value === "on") {
      setInStoreChecked(1);
      setShipToAddressChecked(1);
      setdeliveryOption(2);
      setDeliveryOptions(2);
      setaddAddressCard(true);
    }
  };

const handleValidAddAddress=() =>{
  let isValid=true;
  if(deliveryOption===2 || getDeliveryOption()==2){
    console.log("shippingAddressInfo",shippingAddressInfo)
    if(shippingAddressInfo===undefined || shippingAddressInfo===null ){
      isValid=false;
      setaddAddressError((prevState) => ({
        ...prevState,
        ["addAddress_Error"]: alerts.ENTER_ADDADDRESS,
      }));
      
    }
  }
  setDeliveryOptions(deliveryOption);
  return isValid
}

  const handlePaymentCheckout = () => {
    if (guestUser === true) {
      setGusetUserSignOutShow(true);
    } else {
      let isValid=deliveryOptionValidation();
      let isAddressValid=handleValidAddAddress();
      console.log("isValid",isValid);
      console.log("isAddressValid",isAddressValid);
      if(isValid && isAddressValid){
        setQty(count);
        setDeliveryOptions(deliveryOption);
        navigate('/payment', {
          state: {
            product_info: productInfo,
            user_address: shippingAddressInfo,
            deliveryOption: deliveryOption,
            quantity: count,
            customer_created: customer,
          },
        });
      }
      
    }
  };

  const handlePlaceSelected = (place: any) => {
    setshippingAddressData((shippingAddressData) => ({
      ...shippingAddressData,
      address1: '',
      address2: '',
      city: '',
      statename: '',
      countryname: '',
      zip: '',
    }));
    setPlace(place.address_components);
    console.log(place.address_components);
  };

  return (
    <div className="inner-layout">
      {load && <Loader/>}

        <>
          <Container fluid className="page-header">
            <Container className="d-flex align-items-center justify-content-between header-inner">
              <Col md="6" className="d-flex align-items-center">
                <div className="vendor-title d-flex align-items-center">
                  <div className="vendor-dtls p-0">
                    <h2>{productInfo?.prod_name}</h2>
                    <div className="item-cat-wrap d-flex">
                      {productInfo?.category_id === categoryIds.PRODUCTS ? (
                        <Category category="Products" item={productInfo?.subcategory_name} products />
                      ) : productInfo?.category_id === categoryIds.SERVICES ? (
                        <Category category="Services" item={productInfo?.subcategory_name} services />
                      ) : productInfo?.category_id === categoryIds.FOOD ? (
                        <Category category="Food" item={productInfo?.subcategory_name} food />
                      ) : productInfo?.category_id === categoryIds.THINGSTODO ? (
                        <Category category="Thingstodo" item={productInfo?.subcategory_name} thingstodo />
                      ) : productInfo?.category_id === categoryIds.CLASSIFIEDS ? (
                        <Category category="Classifieds" item={productInfo?.subcategory_name} classifieds />
                      ) : productInfo?.category_id === categoryIds.COUPONS ? (
                        <Category category="COUPONS" services />
                      ) : (
                        <></>
                      )}
                      <div className="action-btns d-flex">
                        {vendorInfo.findus_link && (
                          <RWebShare
                            data={{
                              text: `Price: ${productInfo?.prod_price}\n Description: ${productInfo?.prod_description}`,
                              url: vendorInfo?.findus_link,
                              title: productInfo?.prod_name,
                            }}
                            onClick={() => console.log('shared successfully!')}
                          >
                            <span>
                              <ActionBtn tooltip="Share">
                                <ShareIc />
                              </ActionBtn>
                            </span>
                          </RWebShare>
                        )}
                        <ActionBtn onClick={handleShow} tooltip="Flag">
                          <FlagIc />
                        </ActionBtn>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="text-md-end btn-wrap">
                <Button onClick={handleGoBack} variant="secondary">
                  {Strings.Profile.Back}
                </Button>
              </Col>
            </Container>
          </Container>
          <Container fluid>
            <Container className="content-area">
              <Row>
                <Col lg="8" className="product-slider">
                  <Carousel indicators={false} controls={productImage !== null && productImage.length === (0 || 1) ? false : true}>
                    {productImage &&
                      productImage.map((data, key) => (
                        <Carousel.Item>
                          {data?.image_path && youtubePatterns(data?.image_path) && (
                            <YouTube videoId={getIdfromYoutubeLink(data?.image_path)} opts={{ height: '500px', width: '100%' }} />
                          )}
                          {data?.image_path && !youtubePatterns(data?.image_path) && <img src={data?.image_path} alt="" />}
                          {productInfo?.freshness_id === CONSTANT.STATUS_1 ? <span className="freshness-tag position-absolute">{productInfo?.freshness_name}</span> : ''}
                        </Carousel.Item>
                      ))}
                  </Carousel>
                  <div className="prod-price">${productInfo?.prod_price}</div>
                  <h3>What's Great About This!</h3>
                  <p>{productInfo?.whats_great}</p>
                  <h3>Description</h3>
                  <p>{productInfo?.prod_description}</p>
                  {productInfo?.posted_date && productInfo?.valid_to && productInfo?.category_id === categoryIds.COUPONS && (
                    <Row className="coupon-validity">
                      <Col md="4">
                        Valid From: <span>{moment(productInfo?.posted_date).format('MMM D, YYYY')}</span>
                      </Col>
                      <Col md="4">
                        Valid Till: <span>{moment(productInfo?.valid_to).format('MMM D, YYYY')}</span>
                      </Col>
                    </Row>
                  )}
                  {productInfo?.category_id === categoryIds.COUPONS && (
                    <>
                      <h3>Coupon Code</h3>
                      <Row>
                        <Col lg="4">
                          <div className="apply-coupon mb-4">
                            <InputGroup>
                              <Form.Control disabled value={productInfo?.couponName} />
                              <Button onClick={() => handleCopyCoupon()}>Copy</Button>
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  {productInfo?.prod_availability && productInfo?.category_id === categoryIds.SERVICES && (
                    <div className="prod-time">
                      Hours
                      <br />
                      <span>{productInfo?.prod_availability}</span>
                    </div>
                  )}
                  {productInfo?.posted_date && productInfo?.valid_till && productInfo?.category_id === categoryIds.CLASSIFIEDS && (
                    <div className="prod-time">
                      Posted Date:
                      <span style={{ paddingRight: 25 }}> {moment(productInfo?.posted_date).format('MMM D, YYYY')}</span>
                      {/* <br/> */}
                      Valid Till:
                      <span> {moment(productInfo?.valid_till).format('MMM D, YYYY')}</span>
                    </div>
                  )}
                  {productInfo?.posted_date && productInfo?.category_id === categoryIds.THINGSTODO && (
                    <>
                      <div className="prod-time">
                        {/* {productInfo.delivery_type_ids && productInfo.delivery_type_ids.map((data) => <ServiceItem type={data} />)} */}
                        Event Date & Time:
                        <span>{moment(productInfo?.posted_date).format('MMM D, YYYY · h:mm A')}</span>
                      </div>
                    </>
                  )}
                  {productInfo?.category_id === categoryIds.PRODUCTS || productInfo?.category_id === categoryIds.FOOD || productInfo?.category_id === categoryIds.THINGSTODO ? (
                    <>
                      <Row>
                        <Col lg="3">
                          <label style={{ marginBottom: 15 }}>Quantity</label>
                         
                          <NBInputQty value={count} increment={countIncrement} decrement={countDecrement} id="quantity" name="quantity" />
                        </Col>
                        {guestUser === true || productInfo?.deliveryOption=== CONSTANT.STATUS_1 ||
                          productInfo?.category_id > categoryIds.FOOD
                        ? (
                          ''
                        ) : (
                          <>
                            <Col lg="9">
                              <label style={{ marginBottom: 15 }} className="d-block">
                                {Strings.VendorDetails.Shipping.Label1}
                              </label>
                              <div className="delivery-options">
                                <Form.Check
                                  inline
                                  label={Strings.VendorDetails.Shipping.Label2}
                                  name="In-Store Pickup"
                                  onChange={handleInStoreCheckBoxChange}
                                  checked={inStoreChecked === 0 ? true : false}
                                />
                                <Form.Check
                                  inline
                                  label={Strings.VendorDetails.Shipping.Label3}
                                  name="Ship To My Address"
                                  onChange={handleShipAddressCheckBoxChange}
                                  checked={inStoreChecked === 1 ? true : false }
                                />
                              </div>
                              {deliveryError.delivery_optionError && deliveryError.delivery_optionError !== '' ? <Form.Text className='error'style={{ color: 'red' }}>
                              {deliveryError.delivery_optionError}
                              </Form.Text> : ""}
                            </Col>
                            
                          </>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {productInfo.delivery_type_ids && productInfo.delivery_type_ids.length !== 0 && (
                    <>
                      <h3>Additional Info</h3>
                      <div className="service-types d-flex">{productInfo.delivery_type_ids && productInfo.delivery_type_ids.map((data) => <ServiceItem type={data} />)}</div>
                    </>
                  )}
                  {addAddressCard ? (
                    <>
                      <h3>{Strings.VendorDetails.Shipping.Subhead}</h3>
                      <div className="shipping-address">
                        {!shippingAddressInfo ? (
                           <>
                           <div>
                           <Link to="#" onClick={addAddressShowModal}>
                             {Strings.VendorDetails.Shipping.Add}{' '}
                           </Link>
                           </div>
                           <div>
                           {addAddressError.addAddress_Error && addAddressError.addAddress_Error  !== '' ? <Form.Text className='error'style={{ color: 'red' }}>
                                 {addAddressError.addAddress_Error }
                               </Form.Text> : ""}
                            </div>
                           </>
                        ) : (  
                          <>
                          <Row>
                            <Col lg="5">
                              <Card>
                                <Card.Body className="position-relative">
                                  <div className="edit position-absolute">
                                    <EditAddress onClick={handleEditAddressChanges} />
                                  </div>
                                  <span className="name">{shippingAddressInfo?.fullname}</span>
                                  <span>
                                   {shippingAddressInfo?.address2?`${shippingAddressInfo?.address1.trim()}, ${shippingAddressInfo.address2},` : `${shippingAddressInfo?.address1}`}
                                  </span>
                                  <span>
                                  {shippingAddressInfo?.city + ", " }{shippingAddressInfo?.statename + ", "}{shippingAddressInfo?.zip}
                                  </span>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {productInfo.safetradeavailable === error_code.Code_1 && (
                    <div className="safe-trade d-flex align-items-center">
                      <Link to="https://safetradespots.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                        Safe Trade Available
                      </Link>
                    </div>
                  )}
                  {productInfo.category_id && productInfo?.category_id === categoryIds.SERVICES && (
                    <div className="ride-type">
                      <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                        <NavLink to="https://www.google.com/maps/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="need-ride" id="tbg-radio-2">
                            Need a ride there?
                          </Button>
                        </NavLink>
                        <NavLink to="https://www.uber.com/in/en/ride/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="uber" id="tbg-radio-2">
                            Uber
                          </Button>
                        </NavLink>
                        <NavLink to="https://www.lyft.com/" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer">
                          <Button variant="light" className="lyft" id="tbg-radio-2">
                            Lyft
                          </Button>
                        </NavLink>
                      </ToggleButtonGroup>
                    </div>
                  )}
                  {productInfo?.category_id !== categoryIds.COUPONS && (
                    <Button onClick={handlePaymentCheckout} variant="primary">
                      {productInfo?.category_id === categoryIds.SERVICES ? (
                        <>Book Service</>
                      ) : productInfo?.category_id === categoryIds.THINGSTODO ? (
                        <>Book Now</>
                      ) : productInfo?.category_id === categoryIds.CLASSIFIEDS ? (
                        <>Apply</>
                      ) : (
                        <>Checkout</>
                      )}
                    </Button>
                  )}
                </Col>
                <Col lg="4">
                  <div className="contact-vendor mt-0 mb-4">
                    <h3 className="pt-0">{vendorInfo?.business_name}</h3>
                    <div className="address-vendor">
                      <p className="title">{Strings.VendorDetails.Address}</p>
                      <p className="address-txt">
                        {vendorInfo.address1 ? `${vendorInfo?.address1.trim()},` : ''} {vendorInfo.city ? `${vendorInfo?.city.trim()},` : ''}
                        {vendorInfo.country ? `${vendorInfo?.country.trim()},` : ''} {vendorInfo.statename ? `${vendorInfo?.statename.trim()}  ` : ''}
                        {vendorInfo.zip ? ` ${vendorInfo?.zip.trim()}` : ''}
                      </p>
                    </div>
                    <div className="other-dtls-vendor">
                      {vendorInfo.business_phoneno && (
                        <span onClick={handleCopyPhoneNo} className="phone d-inline-block">
                          {phoneFormat(vendorInfo?.business_phoneno)}
                        </span>
                      )}
                      {vendorInfo.findus_link && (
                        <NavLink to={vendorInfo?.findus_link} target="_blank" style={{ color: 'black' }}>
                          <span className="website d-inline-block">Find Us on</span>
                        </NavLink>
                      )}
                    </div>
                    {vendorInfo?.sellerpolicy_text && (
                      <>
                        <h3 className="mt-4">{Strings.VendorDetails.SellerPolicy}</h3>
                        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                          {vendorInfo?.sellerpolicy_text && <p className="common-content">{vendorInfo?.sellerpolicy_text}</p>}
                        </Scrollbars>
                      </>
                    )}
                    {vendorInfo?.sellerpolicy_link && (
                      <>
                        <h3 className="mt-4">{Strings.VendorDetails.SellerPolicy}</h3>
                        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={160}>
                          {vendorInfo?.sellerpolicy_link && (
                            <NavLink to={vendorInfo?.sellerpolicy_link} target="_blank" className="common-content">
                              {vendorInfo?.sellerpolicy_link}
                            </NavLink>
                          )}
                        </Scrollbars>
                      </>
                    )}
                  </div>
                  <AppsAdRight />
                </Col>
              </Row>
            </Container>
            {/* Report Modal */}
            <Modal show={show} onHide={handleClose} centered backdrop="static" className="report">
              <Modal.Header>
                <Modal.Title>{Strings.VendorDetails.Report.Title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-5">
                <p className="mb-4">{Strings.VendorDetails.Report.Description}</p>
                <NBSelect
                  id="select"
                  name="select"
                  options={reportReasons}
                  handleSelectChange={handleSelectChange}
                  value={selectedValue}
                  label={Strings.VendorDetails.Report.Label1}
                />
                <NBInputGroup
                  id="Tellusmore"
                  name="Tellusmore"
                  label={Strings.VendorDetails.Report.Label2}
                  as="textarea"
                  maxLength={200}
                  rows={5}
                  value={reportText}
                  onChange={handleReportTextChange}
                />
                <Col className="button-nav">
                  <Button variant="secondary" onClick={handleClose}>
                    {Strings.VendorDetails.Report.Btn1}
                  </Button>
                  <Button variant="primary" onClick={reportProductFunction}>
                    {Strings.VendorDetails.Report.Btn2}
                  </Button>
                </Col>
              </Modal.Body>
            </Modal>
            <Modal show={gusetUserSignOutShow} onHide={signoutGuestConfirmationClose} centered backdrop="static">
              <Modal.Body className="otp-verify">
                <h1 className="text-center">{Strings.Login.Title}</h1>
                <span className="text-center d-block pt-3">
                  Sign in to continue.
                  <br />
                </span>
                <div className="btn-wrap d-flex pt-4">
                  <Button onClick={signoutGuestConfirmationClose} variant="secondary">
                    {Strings.Header.DropDownMenu.signOut.Btn2}
                  </Button>
                  <Button onClick={logoutGuestUser} variant="primary">
                    {Strings.Header.DropDownMenu.signOut.Btn1}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            {!guestUser && (
              <Chat
                handleView={handleView}
                questionList={questionList}
                productMessageHistory={productMessagesHistory}
                changeSelectedMsg={handleselectedMessage}
                selectedMsg={selectedMsg}
                questionsAsked={questionAsked}
                send={handleSendMsg}
                sendBtnActive={!selectedMsg}
                viewChat={viewChat}
              />
            )}
            <Modal show={addAddressShow} onHide={signoutGuestConfirmationClose} centered backdrop="static" size="lg" className="report">
              <Modal.Header>
                <Modal.Title>{shippingAddressInfo ? Strings.VendorDetails.Shipping.AddAddress.Title1 : Strings.VendorDetails.Shipping.AddAddress.Title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="pt-4">
                <Row>
                  <Col lg="6">
                    <NBInputGroup
                      name="fullname"
                      id="fullname"
                      type="text"
                      maxLength="25"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label1}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.fullname}
                      error={shippingAddressError?.fullname_error}
                    />
                  </Col>
                  <Col lg="6">
                  <div className="nb-autocomplete">
                    <AddressAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY || ''}
                      address={shippingAddressData?.address1}
                      handlePlaceSelected={handlePlaceSelected}
                      handleChange={handleShippingAddressChange}
                      label={Strings.VendorDetails.Shipping.AddAddress.Label2}
                    />
                       {shippingAddressError?.address1_error && shippingAddressError?.address1_error !== '' ? 
                        <Form.Text className='error'style={{ color: 'red' }}>
                        {shippingAddressError?.address1_error}
                       </Form.Text> : ""}
                       </div>
                  </Col>
                  <Col lg="6">
                    <NBInputGroup
                      name="address2"
                      id="address2"
                      type="text"
                      maxLength="50"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label3}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.address2}
                      readOnly
                    />
                  </Col>
                  <Col lg="6">
                    <NBPhoneInput
                      name="phone"
                      id="phone"
                      type="number"
                      maxLength="14"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label4}
                      onChange={handleMobileInputChange}
                      value={shippingAddressData?.phone}
                      error={shippingAddressError?.Phone_error}
                    />
                  </Col>
                  <Col lg="6">
                    <NBInputGroup
                      name="city"
                      id="city"
                      type="text"
                      maxLength="25"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label5}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.city}
                      error={shippingAddressError?.city_error}
                      readOnly
                    />
                  </Col>
                  <Col lg="6">
                    <NBInputGroup
                      name="statename"
                      id="statename"
                      type="text"
                      maxLength="25"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label6}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.statename}
                      error={shippingAddressError?.statename_error}
                      readOnly
                    />
                  </Col>
                  <Col lg="6">
                    <NBInputGroup
                      name="countryname"
                      id="countryname"
                      type="text"
                      maxLength="25"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label7}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.countryname}
                      error={shippingAddressError?.countryname_error}
                      readOnly
                    />
                  </Col>
                  <Col lg="6">
                    <NBInputGroup
                      name="zip"
                      id="zip"
                      type="text"
                      maxLength="6"
                      label={Strings.VendorDetails.Shipping.AddAddress.Label8}
                      onChange={handleShippingAddressChange}
                      value={shippingAddressData?.zip}
                      error={shippingAddressError?.zip_error}
                      readOnly
                    />
                  </Col>
                </Row>
                <Col className="button-nav pt-3">
                  <Button
                    onClick={() => {
                      addAddressClose();
                      setEditable(false);
                      handleValidAddAddress();
                      setDeliveryOptions(deliveryOption);
                    }}
                    variant="secondary"
                  >
                    {Strings.VendorDetails.Shipping.AddAddress.Button1}
                  </Button>
                  <Button variant="primary" onClick={onSubmit}>
                    {Strings.VendorDetails.Shipping.AddAddress.Button2}
                  </Button>
                </Col>
              </Modal.Body>
            </Modal>
          </Container>
        </>

    </div>
  );
};

export default ProductDetail;
