/**
 * @file   src\containers\Contact.tsx
 * @brief  Contact page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Col, Container, Row, Modal, Button } from 'react-bootstrap';
import '../assets/styles/home.scss';
import Strings from '../assets/strings/Strings.json';
import HomeSearch from '../components/SearchMain';
import Product from '../components/Products';
import AppsAd from '../components/AppsAd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { locationListing } from '../store/actions/authAction';
import { resetLocationListingStateState } from '../store/slices/authSlice';
import { resetVendorPrdtsState } from '../store/slices/productSlice';
import { categoryList } from '../store/actions/productAction';
import { userLocationUpdate } from '../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { useNavigate } from 'react-router-dom';
import * as CONSTANT from '../utils/constants';
import { setLatLong, getLatLong, getNationalWide, setVendorInfo, removeCategory, removeVInfo, getUser, setFilter, getFilter, removeFilter } from '../utils/localStorage';
import { toast } from 'react-toastify';
import * as alerts from '../utils/alerts';
import { error_code } from '../utils/enums';
import Loader from '../components/Loader';

const Home = () => {
  type Price = {
    min: string;
    max: string;
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const national_wide = getNationalWide();
  const user_info = JSON.parse(getUser());
  const latLong = JSON.parse(getLatLong());
  const filterData = JSON.parse(getFilter());

  const LocationListingResponseData = useAppSelector((RootReducer) => RootReducer.auth.locationListing);
  const CategoryListingResponseData = useAppSelector((RootReducer) => RootReducer.product.categoryList);

  const [locationZipcode, setLocationZipcode] = useState<any>(null);

  const [vendorData, setVendorData] = useState<any>('');
  const [categoryListData, setCategoryListData] = useState<any>([]);
  const [subCategoryListData, setSubCategoryListData] = useState<any>([]);
  const [maxValueError, setMaxValueError] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [lat, setLat] = useState<any>('');
  const [long, setLong] = useState<any>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [miles, setMiles] = useState<string>('');
  const [filterMiles, setFilterMiles] = useState<string>(filterData?.filterMiles ? filterData.filterMiles : '');
  const [filterMilesError, setFilterMilesError] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [subCatagoryValue, setSubCatagoryValue] = useState<any>(filterData?.subcategoryId && filterData.subcategoryId !== '' ? filterData.subcategoryId : []);
  const [selected, setSelected] = useState<boolean>(false);
  const [zipcodeSelect, setZipcodeSelect] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<Price>({
    min: filterData?.minPrice ? filterData.minPrice : '',
    max: filterData?.maxPrice ? filterData.maxPrice : '',
  });
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);

  // handle lat long change and calls the vendor listing api call
  useEffect(() => {
    if (lat && long) {
      if (national_wide === ('' || null)) {
        const params = {
          lat: lat,
          long: long,
          searchKey: address,
          subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
          minPrice: priceRange.min,
          maxPrice: priceRange.max,
          zipCode: zipCode,
          city: city,
          miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
          filterMiles,
        };
        const payload = {
          userLocationLat: lat,
          userLocationLon: long,
          userId: user_info && user_info.user_id ? String(user_info?.user_id) : '',
          zipCode: zipCode,
          device_token: '',
          deviceType: 0,
        };
        setLatLong(JSON.stringify(params));
        dispatch(locationListing(params));
        dispatch(userLocationUpdate(payload));
        dispatch(resetVendorPrdtsState());
      } else {
        const params = {
          lat: lat,
          long: long,
          searchKey: address,
          isNationWide: error_code.Code_1,
          zipCode: zipCode,
          subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
          minPrice: priceRange.min,
          maxPrice: priceRange.max,
          city: city,
          miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
          filterMiles,
        };
        const payload = {
          userLocationLat: lat,
          userLocationLon: long,
          userId: user_info && user_info.user_id ? String(user_info?.user_id) : '',
          zipCode: zipCode,
          device_token: '',
          deviceType: error_code.Code_0,
        };
        setLatLong(JSON.stringify(params));
        dispatch(locationListing(params));
        dispatch(userLocationUpdate(payload));
      }
    }
  }, [lat, long, address]);

  // handle nation wide change and calls the vendor listing api call
  useEffect(() => {
    if (national_wide !== ('' || null)) {
      const params = {
        lat: '',
        long: '',
        searchKey: address,
        subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
        isNationWide: error_code.Code_1,
        miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
        filterMiles,
      };
      dispatch(locationListing(params));
    } else {
      const params = {
        lat: lat,
        long: long,
        searchKey: address,
        subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
        miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
        filterMiles,
      };
      dispatch(locationListing(params));
    }
  }, [national_wide]);

  // handle window scroll up top and get lat,long and zipcode from local storage and calls getcurrent location if local storage is empty.
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    dispatch<any>(categoryList());
    dispatch(resetVendorPrdtsState());
    removeCategory();
    if (latLong !== ('' || null)) {
      setLat(latLong.lat);
      setLong(latLong.long);
      setZipCode(latLong.zipCode);
      if (latLong.city) {
        setCity(latLong.city);
      }
      if (latLong.miles) {
        setMiles(latLong.miles);
      }
    } else {
      getCurrentLocation(error_code.Code_1);
    }
  }, []);

  useEffect(() => {
    // Include Zendesk Web Widget script dynamically
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9cd21fbd-bfb8-40b5-a3cb-f9cc50c304f7';
    script.id = 'ze-snippet';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      // Cleanup when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  // handle category listing response data
  useEffect(() => {
    if (CategoryListingResponseData.isLoading === false && CategoryListingResponseData.isSuccess === true) {
      if (CategoryListingResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        setCategoryListData(CategoryListingResponseData.resultInfo.category_info);
        setSubCategoryListData(CategoryListingResponseData.resultInfo.subcategory_info);
      } else {
        toast.error(CategoryListingResponseData?.errorMessage);
      }
    }
  }, [CategoryListingResponseData]);

  // handle location listing response data
  useEffect(() => {
    if (LocationListingResponseData.isLoading === false && LocationListingResponseData.isSuccess === true) {
      if (LocationListingResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        setVendorData(LocationListingResponseData.userInfo);
      } else if (LocationListingResponseData.errorCode === CONSTANT.API_ERRCODE) {
        setVendorData('');
      }
      dispatch(resetLocationListingStateState());
      setLoad(false);
    }
  }, [LocationListingResponseData]);

  const filterIntialValues = () => {
    setFilterMiles(filterData?.filterMiles ? filterData.filterMiles : '');
    setSubCatagoryValue(filterData?.subcategoryId && filterData.subcategoryId !== '' ? filterData.subcategoryId : []);
    setPriceRange({ max: filterData?.minPrice ? filterData.minPrice : '', min: filterData?.maxPrice ? filterData.maxPrice : '' });
  };

  // function to get location on tapping the search.
  const getLocation = () => {
    setZipcodeSelect(false);
    setLoad(true);
    setFilterMilesError('');
    setMaxValueError('');
    if (zipCode === '') {
      toast.error(alerts.INVALID_ZIP_CODE);
      setLoad(false);
      // } else if (filterMiles !== '' && parseInt(filterMiles, CONSTANT.STATUS_10) === CONSTANT.STATUS_0) {
      //   setFilterMilesError(alerts.INVALID_MILES_MIN);
      //   setLoad(false);
      // } else if (filterMiles !== '' && parseInt(filterMiles, CONSTANT.STATUS_10) > CONSTANT.STATUS_50) {
      //   setFilterMilesError(alerts.INVALID_MILES);
      //   setLoad(false);
    } else if (miles !== '' && parseInt(miles, CONSTANT.STATUS_10) > CONSTANT.STATUS_50) {
      toast.error(alerts.INVALID_MILES);
      setLoad(false);
    } else if (miles !== '' && parseInt(miles, CONSTANT.STATUS_10) === CONSTANT.STATUS_0) {
      toast.error(alerts.INVALID_MILES_MIN);
      setLoad(false);
    } else if (priceRange.max !== '' && priceRange.min !== '' && parseFloat(priceRange.min) > parseFloat(priceRange.max)) {
      setMaxValueError(alerts.MAX_VALUE_ERROR);
      setLoad(false);
    } else {
      handleGetLocation(zipCode);
      filterIntialValues();
    }
  };

  // function get the current location lat and long of the user
  const getCurrentLocation = (intial: number) => {
    setLoad(true);
    setLat('');
    setLong('');
    setZipcodeSelect(false);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLong(longitude);
          handleGetLocationAddress(latitude, longitude);
        },
        (error: GeolocationPositionError) => {
          console.error('Error getting location:', error);
          setLoad(false);
          if (error.code === error_code.Code_1 && intial !== error_code.Code_1) {
            setShowLocationModal(true);
          }
        },
      );
    } else {
      console.error('Geolocation is not supported by your browser.');
      setLoad(false);
    }
  };

  // handle search name on change
  const handleAddressChanges = (event: any) => {
    setAddress(event.target.value);
  };

  // function to navigate to corresponding selected vendor details page
  const navigateToVendorInfo = (vendorInfo: any) => {
    navigate('/vendordetails', { state: { zipcode: zipCode } });
    setVendorInfo(JSON.stringify(vendorInfo));
    // window.location.reload();
  };
  // function to get the lat and long of the user entered zipcode
  const handleGetLocation = (zipcode: any) => {
    setLat('');
    setLong('');
    const requestURL = `${process.env.REACT_APP_API_URL}V1/mapsgeocode?postal_code=${zipcode}`;
    axios
      .get(requestURL, {
        headers: {
          Api_key: process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        if (response.data.data.results.length > 0) {
          const result = response.data.data.results[0].geometry.location;
          setLat(result.lat);
          setLong(result.lng);
          const resultCity = response.data.data.results[0];
          const cityName = resultCity.address_components.find((component: { types: string | string[] }) => component.types.includes('locality'))?.long_name;
          const params = {
            lat: result.lat,
            long: result.lng,
            searchKey: address,
            subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
            minPrice: priceRange.min,
            maxPrice: priceRange.max,
            zipCode: zipcode,
            city: cityName || city,
            miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
          };
          setLatLong(JSON.stringify(params));
          setCity(cityName || city);
        } else {
          toast.error(alerts.ZIP_CODE_VALID);
          // Resetting state as necessary, make sure all functions and state setters are defined
          setLocationZipcode(null);
          setZipCode('');
          setCity('');
          setLat('');
          setLong('');
          // Similar reset for other states as needed
        }
      })
      .catch((err) => {
        // Ensure 'setLoad' and other functions are defined and used correctly
        setLoad(false);
        setLocationZipcode(null);
      });
  };

  // function to get zipcode and city name from the lat and long
  const handleGetLocationAddress = (lat: any, long: any) => {
    // Replace 'YOUR_API_KEY' with your Google Maps API key
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.results.length > error_code.Code_0) {
          const result = response.data.results[0];
          const zipCode = result.address_components.find((component: { types: any }) => component.types.includes('postal_code'))?.long_name;
          const city = result.address_components.find((component: { types: string | string[] }) => component.types.includes('locality'))?.long_name;
          const params = {
            lat: lat,
            long: long,
            searchKey: address,
            subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
            minPrice: priceRange.min,
            maxPrice: priceRange.max,
            zipCode: zipCode,
            city: city,
            miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
          };
          setLatLong(JSON.stringify(params));
          setCity(city === undefined ? '' : city);
          setZipCode(zipCode);
        } else {
          toast.error(alerts.INVALID_ZIP_CODE);
          setLocationZipcode(null);
        }
      })
      .catch((err) => {
        setLocationZipcode(null);
      });
  };

  // handle the subcategory changes
  const handleSubCategoryChange = (value: any) => {
    const index = subCatagoryValue.indexOf(value);
    if (index !== -1) {
      // Value exists in the array, so pop it
      const newArray = [...subCatagoryValue];
      newArray.splice(index, 1);
      setSubCatagoryValue(newArray);
    } else {
      // Value doesn't exist in the array, so push it
      setSubCatagoryValue([...subCatagoryValue, value]);
    }
  };

  // handle the filter apply button and calls the vendor listing api call
  const filterChanges = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    setFilterMilesError('');
    if (zipCode === '') {
      toast.error(alerts.INVALID_ZIP_CODE);
      setShowDropdown(false);
    } else if (miles !== '' && parseInt(miles, CONSTANT.STATUS_10) > CONSTANT.STATUS_50 && filterMiles === '') {
      toast.error(alerts.INVALID_MILES);
      setShowDropdown(false);
    } else if (miles !== '' && parseInt(miles, CONSTANT.STATUS_10) === CONSTANT.STATUS_0 && filterMiles === '') {
      toast.error(alerts.INVALID_MILES_MIN);
      setShowDropdown(false);
    } else {
      const params = {
        subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
        lat: lat,
        long: long,
        searchKey: address,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
        miles: miles !== '' && parseInt(miles, CONSTANT.STATUS_10) > CONSTANT.STATUS_50 ? '' : miles,
        filterMiles,
      };
      if (filterMiles !== '' && parseInt(filterMiles, CONSTANT.STATUS_10) > CONSTANT.STATUS_50) {
        setFilterMilesError(alerts.INVALID_MILES);
      } else if (filterMiles !== '' && parseInt(filterMiles, CONSTANT.STATUS_10) === CONSTANT.STATUS_0) {
        setFilterMilesError(alerts.INVALID_MILES_MIN);
      } else if (parseFloat(priceRange.min) <= parseFloat(priceRange.max) || priceRange.max === '' || priceRange.min === '') {
        if (national_wide === ('' || null)) {
          dispatch(locationListing(params));
          setFilter(
            JSON.stringify({
              subcategoryId: params.subcategoryId,
              minPrice: params.minPrice,
              maxPrice: params.maxPrice,
              filterMiles,
            }),
          );
        } else {
          const params = {
            subcategoryId: subCatagoryValue.length === error_code.Code_0 ? '' : subCatagoryValue,
            lat: lat,
            long: long,
            searchKey: address,
            minPrice: priceRange.min,
            maxPrice: priceRange.max,
            isNationWide: error_code.Code_1,
            miles: miles !== '' && parseInt(miles, 10) > 50 ? '' : miles,
            filterMiles,
          };
          dispatch(locationListing(params));
          setFilter(
            JSON.stringify({
              subcategoryId: params.subcategoryId,
              minPrice: params.minPrice,
              maxPrice: params.maxPrice,
              filterMiles,
            }),
          );
        }
        setMaxValueError('');
        setShowDropdown(false);
      } else {
        setMaxValueError(alerts.MAX_VALUE_ERROR);
      }
    }
  };

  // handle price on change.
  const priceChange = (event: any) => {
    if (event.target.name === 'Min') {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['min']: event.target.value,
      }));
    }
    if (event.target.name === 'Max') {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['max']: event.target.value,
      }));
    }
  };

  // handle price on change.
  const filterMilesChange = (event: any) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setFilterMiles(value);
    }
  };

  // handle reset the value of the filter
  const handleClear = () => {
    setSubCatagoryValue([]);
    setPriceRange({ max: '', min: '' });
    removeFilter();
    setMaxValueError('');
    setFilterMiles('');
  };

  // handle the zipcode on change
  const handleZipcodeChange = (event: any) => {
    setZipCode('');
    setCity('');
    setSubCatagoryValue([]);
    setPriceRange({ max: '', min: '' });
    setAddress('');
    setZipCode(event?.target.value);
  };

  // handle the zipcode on change
  const handleMilesChange = (event: any) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setMiles(value);
    }
  };

  // show the modal for the location disabled
  const showLocationModalClose = () => {
    setShowLocationModal(false);
  };

  // handle max value on change and convert into decimal
  const changeMaxDecimal = () => {
    let maxPrice = priceRange.max;
    if (maxPrice != '') {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['max']: (parseFloat(maxPrice) || 0).toFixed(2),
      }));
    } else {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['max']: '',
      }));
    }
  };

  // handle min value on change and convert into decimal
  const changeMinDecimal = () => {
    let minPrice = priceRange.min;
    if (minPrice != '') {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['min']: (parseFloat(minPrice) || 0).toFixed(2),
      }));
    } else {
      setPriceRange((priceRange) => ({
        ...priceRange,
        ['min']: '',
      }));
    }
  };

  //handle image to display in vendor profile
  const getProfileImage = (item: any) => {
    let foundObject;
    if (item) {
      foundObject = item.find((obj: { filetype: any }) => obj.filetype !== error_code.Code_3);
      if (foundObject) {
        return foundObject.filepath;
      } else {
        return foundObject;
      }
    } else return foundObject;
  };

  const searchOnEnterKey = (event: any) => {
    if (event.keyCode === error_code.Code_13) {
      getLocation();
    }
  };

  return (
    <div className="home-layout">
      <Container fluid className="banner">
        <Container className="banner-content d-flex align-items-center justify-content-center flex-column">
          <h5 className="text-white mb-4 text-center">NearBuys. Where Offers Find You!</h5>
          <HomeSearch
            onClick={getLocation}
            value={address}
            addressOnChange={handleAddressChanges}
            onChange={handleSubCategoryChange}
            categoryList={categoryListData}
            subCategoryList={subCategoryListData}
            applyFliter={filterChanges}
            showDropdown={showDropdown}
            onChangeZipcode={handleZipcodeChange}
            currentLocation={getCurrentLocation}
            maxValue={priceRange.max}
            minValue={priceRange.min}
            priceChange={priceChange}
            onFocus={() => setSelected(true)}
            zipcodeSelect={zipcodeSelect}
            zipcodeSelectChange={() => setZipcodeSelect(true)}
            zipCode_value={zipCode}
            miles={miles}
            // applyBtnDisable={subCatagoryValue.length > error_code.Code_0 || (priceRange.max || priceRange.min) != '' || filterMiles != '' ? false : true}
            applyBtnDisable={false}
            maxValueError={maxValueError}
            clearFilter={handleClear}
            changeMaxDecimal={changeMaxDecimal}
            changeMinDecimal={changeMinDecimal}
            subCatagoryValue={subCatagoryValue}
            setShowDropdown={setShowDropdown}
            setZipcodeSelect={setZipcodeSelect}
            city={city}
            searchOnEnterKey={searchOnEnterKey}
            onChangeMiles={handleMilesChange}
            filterMiles={filterMiles}
            filterMilesChange={filterMilesChange}
            filterMilesError={filterMilesError}
          />
        </Container>
      </Container>
      <Container fluid>
        <Container className="home-container">
          {national_wide ? <h2>{Strings.VendorDetails.Subhead3}</h2> : <h2>{Strings.VendorDetails.Subhead1}</h2>}
          <Row className="mb-4">
            {vendorData.length > 0 ? (
              vendorData.map((items: any) => (
                <Col xl="3" lg="4" md="6" xs="12">
                  <Product
                    type={CONSTANT.STATUS_1}
                    title={items?.business_name}
                    image={getProfileImage(items.vendor_files)}
                    onClick={() => {
                      navigateToVendorInfo(items);
                    }}
                  />
                </Col>
              ))
            ) : (
              <Col className="text-center mt-3">No vendors available.</Col>
            )}
          </Row>
          <AppsAd />
        </Container>
      </Container>
      <Modal show={showLocationModal} onHide={showLocationModalClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h5 className="text-center d-block pt-3">
            {alerts.LOCATION_PERMISSION_DENIED}
            <br />
          </h5>
          <div className="align-items-center justify-content-center d-flex pt-4">
            <Button onClick={showLocationModalClose} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {load && <Loader/> }
      
    </div>
  );
};

export default Home;
