/**
 * @file   src\containers\ReturnRequest.tsx
 * @brief  Product return requst page
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import '../assets/styles/return.scss';
import Burger from '../assets/img/Slide3.jpg';
import NBInputGroup from '../components/NBInputGroup';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { refundDetails, requestRefund } from '../store/actions/productAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { resetRequestRefundState } from '../store/slices/productSlice';
import { useAppSelector } from '../hooks/index';
import * as CONSTANT from '../utils/constants';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Payment = () => {
  interface refundInfoData {
    total: string;
    item_price: string;
    processing_fee: string;
    discountAmount: string;
    quantity: string;
    product_name: string;
    business_name: string;
    purchase_date: string;
    sellerpolicy_link: string;
    sellerpolicy_text: string;
    product_image: string;
    refund_status: any;
  }
  interface iRefundReason {
    refund_reason: string;
  }
  interface iRefundReasonError {
    refund_reason_error: string;
  }
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();
  const refundInfoResponse = useAppSelector((RootReducer) => RootReducer.product.refundDetails);
  const requestRefundResponse = useAppSelector((RootReducer) => RootReducer.product.requestRefund);
  const [refundInfo, setRefundInfo] = useState<refundInfoData>({
    total: '',
    item_price: '',
    processing_fee: '',
    quantity: '',
    product_name: '',
    business_name: '',
    purchase_date: '',
    sellerpolicy_link: '',
    sellerpolicy_text: '',
    product_image: '',
    refund_status: '',
  });
  const [refundReason, setRefundReason] = useState<iRefundReason>({
    refund_reason: '',
  });

  const [refundReasonError, setRefundReasonError] = useState<iRefundReasonError>({
    refund_reason_error: '',
  });
  const [load, setLoad] = useState<boolean>(true);

  const dispatch = useDispatch();
  const refundDetailsApi = (payload: any) => dispatch<any>(refundDetails(payload));

  // handle intial refund detail api call.
  useEffect(() => {
    const payload = {
      purchase_id: location.state.purchase_id,
    };
    refundDetailsApi(payload);
  }, []);

  // handle refund information response api data.
  useEffect(() => {
    if (refundInfoResponse.isLoading === false && refundInfoResponse.isSuccess === true) {
      if (refundInfoResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setRefundInfo(refundInfoResponse.prdtInfo.order_info);
      }
      setLoad(false);
    }
  }, [refundInfoResponse]);

  // handle request refund api response.
  useEffect(() => {
    if (requestRefundResponse.isLoading === false && requestRefundResponse.isSuccess === true) {
      if (requestRefundResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(requestRefundResponse.errorMessage);
        navigate('/purchasehistory');
      } else {
        toast.error(requestRefundResponse.errorMessage);
      }
      dispatch<any>(resetRequestRefundState());
    }
  }, [requestRefundResponse]);

  // handle on clicking the refund submit button.
  const onSubmit = () => {
    const payload = {
      purchase_id: location.state.purchase_id,
      refund_reason: refundReason?.refund_reason,
    };
    dispatch<any>(requestRefund(payload));
  };

  // handle data change
  const handleChanges = (event: { target: { name: any; value: string } }) => {
    setRefundReason((reason) => ({
      ...reason,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container className="d-flex align-items-center justify-content-between header-inner">
          <div className="vendor-dtls p-0">
            <h2>Return Request</h2>
          </div>
        </Container>
      </Container>
      {load ? (
        <div className="spinner-outer d-flex justify-content-center">
          <div className=" align-items-center justify-content-center d-flex">
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status"></div>
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901' }} role="status"></div>
          </div>
        </div>
      ) : (
        <Container fluid>
          <Container className="return">
            <Row>
              <Col lg="12">
                <Row>
                  <Col xl="7">
                    <Row className="mb-5">
                      <Col lg="6" className="item-image">
                        <img src={refundInfo.product_image ? refundInfo.product_image : Burger} alt="" />
                      </Col>
                      <Col lg="6" className="product-details py-lg-0">
                        <h4>{refundInfo.product_name}</h4>
                        <h6>{refundInfo.business_name}</h6>
                        <p className="text-light mb-5">
                          Purchase Date: <span className="text-dark ps-1">{refundInfo.purchase_date}</span>
                        </p>
                        <Row>
                          <Col>Quantity</Col>
                          <Col>{refundInfo.quantity}</Col>
                        </Row>
                        <Row>
                          <Col>Item Price</Col>
                          <Col>$ {refundInfo.total}</Col>
                        </Row>
                        <Row>
                          <Col>Discount Amount </Col>
                          <Col>$ {refundInfo.discountAmount}</Col>
                        </Row>
                        <Row>
                          <Col>Processing Fee </Col>
                          <Col>$ {refundInfo.processing_fee}</Col>
                        </Row>
                        <Row>
                          <Col>Amount to be refunded</Col>
                          <Col>$ {refundInfo.item_price}</Col>
                        </Row>
                      </Col>
                    </Row>
                    <p>
                      <span className="fw-semibold">Note:</span> Processing fee will not be refunded
                    </p>
                    <Col className="button-nav">
                      <Button variant="secondary" onClick={() => navigate('/purchasehistory')}>
                        Cancel
                      </Button>
                      <Button variant="primary" disabled={refundInfo.refund_status === 1 ? true : refundInfo.refund_status === 2 ? true : false} onClick={handleShow}>
                        Request Refund
                      </Button>
                    </Col>
                  </Col>
                  <Col xl="5" lg="12" md="12">
                    <div className="return-policy mt-xl-0">
                      {refundInfo.sellerpolicy_text && (
                        <>
                          <h4>Seller's Policy</h4>
                          <p>{refundInfo.sellerpolicy_text}</p>
                        </>
                      )}
                      {refundInfo.sellerpolicy_link && (
                        <>
                          <h4>Seller's Policy</h4>
                          <p>
                            <NavLink to={refundInfo.sellerpolicy_link} target="_blank">
                              {refundInfo.sellerpolicy_link}
                            </NavLink>
                          </p>
                        </>
                      )}
                      <h4>Refund Policy</h4>
                      <p>The refund can be offered only on approval from the vendor, if the refund request is rejected by the vendor, you will not be offered a refund.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {/* Return request */}
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body className="pb-5">
          <h6>Are you sure you want to submit this refund request?</h6>
          <NBInputGroup
            id="Reason"
            name="refund_reason"
            label="Reason For Refund"
            as="textarea"
            rows={5}
            maxLength={250}
            value={refundReason.refund_reason}
            error={refundReasonError.refund_reason_error}
            onChange={handleChanges}
          />
          <Col className="button-nav">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Submit
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payment;
