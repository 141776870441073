/**
 *@file SearchMain.tsx
 *@brief Reusable search component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import { useState, useRef, useEffect } from 'react';
import './SearchMain.scss';
import { Button, DropdownButton, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Filter from '../assets/img/icons/Filter';
import Search from '../assets/img/icons/Search';
import NBInputGroup from '../components/NBInputGroup';
import IcProducts from '../assets/img/icons/Products';
import IcServices from '../assets/img/icons/Services';
import IcThings from '../assets/img/icons/ThingstoDo';
import IcCoupons from '../assets/img/icons/Coupons';
import IcClassifieds from '../assets/img/icons/Classifieds';
import Food from '../assets/img/icons/Food';
import CurrentLocation from '../assets/img/icons/CurrentLocation';
import { Link } from 'react-router-dom';
import * as constants from '../utils/constants';

const HomeSearch = (props: any) => {
  const catagory = props.categoryList;
  const subCatagory = props.subCategoryList;
  const [value, setValue] = useState(props.subCatagoryValue ? props.subCatagoryValue : []);
  const divRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  // handle close of current location style when clicking outside of the style
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divRef.current && inputRef.current && !divRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        props.setZipcodeSelect(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // handle value change
  const handleChange = (val: any) => {
    const index = value.indexOf(val);
    if (index !== -1) {
      const newArray = [...value];
      newArray.splice(index, 1);
      setValue(newArray);
    } else {
      setValue([...value, val]);
    }
  };

  // handle automatic close of filter when clicking outside of the style.
  const changeDropdown = (isOpen: any) => {
    if (isOpen) {
      props.setShowDropdown(true);
      // } else if (props.filterMilesError !== '' || props.maxValueError !== '') {
      //   props.setShowDropdown(true);
    } else {
      props.setShowDropdown(false);
    }
  };

  return (
    <>
      <div className="search-wrap d-flex">
        <input value={props.value} onChange={props.addressOnChange} onFocus={props.onFocus} className="search-box" type="search" placeholder="Search by vendor" />
        <div className="zip-wrap position-relative">
          <input
            className="zip-code"
            placeholder="Enter your Zip Code"
            type="text"
            onClick={props.zipcodeSelectChange}
            onChange={props.onChangeZipcode}
            value={props.city === '' ? props.zipCode_value : props.city}
            maxLength={constants.ZIPCODE_LENGTH}
            ref={inputRef}
            onKeyDown={props.searchOnEnterKey}
          />

          {props.zipcodeSelect && (
            <div className="position-absolute current-location d-flex align-items-center" onClick={props.currentLocation} ref={divRef}>
              <CurrentLocation />
              Use My Current Location
            </div>
          )}
        </div>
        <div className="miles-wrap position-relative">
          {props.miles !== '' && <span className="position-absolute miles-label">Miles</span>}
          <input className="miles-input" placeholder="Enter Miles" type="text" value={props.miles} maxLength={2} onChange={props.onChangeMiles} />
        </div>
        <DropdownButton
          id="dropdown-basic-button"
          variant="secondary"
          align="end"
          autoClose="outside"
          className="filter"
          onToggle={changeDropdown}
          title={<Filter />}
          show={props.showDropdown}
        >
          <div
            className="d-flex justify-content-between"
            onClick={() => {
              props.clearFilter();
              setValue([]);
            }}
          >
            <h3>Filter By</h3>
            <Link to="">Reset All</Link>
          </div>
          <Row>
            <Col md="6">
              <Row>
                <h6>Price Range</h6>
                <Col md="6" className="price-range">
                  <NBInputGroup id="Min" name="Min" type="text" label="Min" maxLength={9} onChange={props.priceChange} value={props.minValue} onBlur={props.changeMinDecimal} />
                </Col>
                <Col md="6" className="price-range">
                  <NBInputGroup
                    id="Min"
                    name="Max"
                    type="text"
                    label="Max"
                    maxLength={9}
                    onChange={props.priceChange}
                    value={props.maxValue}
                    error={props.maxValueError}
                    onBlur={props.changeMaxDecimal}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <h6>Distance</h6>
              <Col md="6">
                <NBInputGroup
                  id="filterMiles"
                  name="filterMiles"
                  type="text"
                  label="Miles"
                  error={props.filterMilesError}
                  maxLength={2}
                  onChange={props.filterMilesChange}
                  value={props.filterMiles}
                />
              </Col>
            </Col>
          </Row>

          {catagory &&
            catagory.map(
              (data1: any, key: number) =>
                data1.category_name &&
                data1.category_name !== 'Coupons' && (
                  <div className="filter-by" key={key}>
                    {data1.category_name && data1.category_name === 'Products' ? (
                      <h4>
                        <IcProducts />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}
                    {data1.category_name && data1.category_name === 'Food' ? (
                      <h4>
                        <Food />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}

                    {data1.category_name && data1.category_name === 'Out and About' ? (
                      <h4>
                        <IcThings />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}

                    {data1.category_name && data1.category_name === 'Services' ? (
                      <h4>
                        <IcServices />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}

                    {data1.category_name && data1.category_name === 'Coupons' ? (
                      <h4>
                        <IcCoupons />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}

                    {data1.category_name && data1.category_name === 'Classifieds' ? (
                      <h4>
                        <IcClassifieds />
                        {data1.category_name}
                      </h4>
                    ) : (
                      ''
                    )}

                    {subCatagory &&
                      subCatagory.map(
                        (data2: any, subKey: number) =>
                          data2.category_id === data1.category_id && (
                            <ToggleButtonGroup
                              key={subKey}
                              type="checkbox"
                              value={value}
                              onChange={() => {
                                props.onChange(data2.subcategory_id);
                                handleChange(data2.subcategory_id);
                              }}
                            >
                              <ToggleButton variant="light" id={`tbg-btn-${data2.subcategory_id}`} value={data2.subcategory_id}>
                                {data2.subcategory_name}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          ),
                      )}
                  </div>
                ),
            )}
          <div className="button-nav">
            {/* <Button onClick={() => { props.clearFilter(); setValue([]); }} variant='success'>Reset</Button> */}
            <Button variant="primary" onClick={props.applyFliter} disabled={props.applyBtnDisable}>
              Apply
            </Button>
          </div>
        </DropdownButton>
        <Button onClick={props.onClick} className="search">
          <Search />
        </Button>
      </div>
    </>
  );
};
export default HomeSearch;
