/**
 * @file   src\containers\DeleteAccount.tsx
 * @brief  Delete Account page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useState, useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userDeleteAccount } from '../store/actions/userAction';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { resetUserAccountDeleteState } from '../store/slices/userSlice';
import '../assets/styles/vendordetails.scss';
import Strings from '../assets/strings/Strings.json';
import NBInputGroup from '../components/NBInputGroup';
import { emailValidation } from '../utils/authHelper';
import * as alerts from '../utils/alerts';
import * as CONSTANT from '../utils/constants';

const DeleteAccount = () => {
  const dispatch = useAppDispatch();

  const deleteAccountResponse = useAppSelector((RootReducer) => RootReducer.user.userDeleteAccount);

  const [show, setShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  // handles otp request api response
  useEffect(() => {
    if (deleteAccountResponse.isLoading === false && deleteAccountResponse.isSuccess === true) {
      if (deleteAccountResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(deleteAccountResponse.errorMessage);
      } else {
        toast.error(deleteAccountResponse.errorMessage);
      }
      dispatch(resetUserAccountDeleteState());
    }
  }, [deleteAccountResponse]);

  // handle modal close and clear error
  const handleClose = () => {
    setShow(false);
    setEmailError('');
    setEmail('');
  };
  const handleShow = () => setShow(true);

  // handle email change
  const handleEmailChange = (event: { target: { name: any; value: string } }) => {
    setEmail(event.target.value);
  };

  // validate email
  const validateEmail = () => {
    let isValid = true;
    const validEmail = emailValidation(email);
    if (!validEmail) {
      setEmailError(alerts.ENTER_VALID_EMAIL);
      isValid = false;
    } else {
      setEmailError('');
    }
    return isValid;
  };

  // Call the delete account api
  const handleSubmitMail = () => {
    const valid = validateEmail();
    if (valid) {
      const param = {
        email,
      };
      dispatch<any>(userDeleteAccount(param));
      handleClose();
    }
  };

  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <h2>{Strings.AccountDelete.Title}</h2>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="content-area">
          <Row>
            <Col lg="8" className="product-outer">
              <h3>{Strings.AccountDelete.Subhead1}</h3>
              <p>
                "Delete Account" is a crucial feature in NearBuys, which allows users to permanently remove their account and associated data from the platform's database. By
                utilizing the "Delete Account" feature, users can safeguard their personal information, prevent further data collection, and disengage from the platform entirely.
                It's an essential tool for maintaining privacy and control over one's online presence. However, users should be aware that deleting an account is usually
                irreversible, resulting in the permanent loss of access to the platform and any associated content or subscriptions. Therefore, it's essential to consider all the
                consequences before proceeding with the deletion process.{' '}
              </p>
              <Button onClick={handleShow} variant="primary">
                {Strings.AccountDelete.Btn}
              </Button>
            </Col>
            <Col lg="4">
              <div className="contact-vendor">
                <div className="nb-address">
                  <h3 className="pt-0">{Strings.AccountDelete.Subhead2}</h3>
                  <p>
                    3245 Hart Ridgre Road
                    <br />
                    Gaylord, MI 49735
                  </p>
                </div>
                <div className="nb-customercare">
                  <h3>{Strings.AccountDelete.Subhead3}</h3>
                  <p className="message">{Strings.AccountDelete.Email}</p>
                  <p className="phone">{Strings.AccountDelete.Phone}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal show={show} onHide={handleClose} centered backdrop="static" className="report">
        <Modal.Header>
          <Modal.Title>{Strings.AccountDelete.Modal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NBInputGroup id="Email" name="Email" error={emailError} value={email} onChange={handleEmailChange} label={Strings.AccountDelete.Modal.Label} />
          <Col className="button-nav">
            <Button variant="secondary" onClick={handleClose}>
              {Strings.AccountDelete.Modal.Btn1}
            </Button>
            <Button variant="primary" onClick={handleSubmitMail}>
              {Strings.AccountDelete.Modal.Btn2}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
